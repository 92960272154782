import React, { cloneElement, useContext } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { ThemeContext } from "styled-components"

import Reveal from "../utils/Reveal"

import Flex from "../atoms/Flex"
import Box from "../atoms/Box"

/**
 * Grid component using Flexbox
 *
 * Columns: number of columns at different widths
 * Gutter: the spacing between items
 * Align: justify items left or center
 */
const FlexGrid = ({
  children,
  columns,
  gutter,
  align = "left",
  maxItemWidth = "none",
  ...rest
}) => {
  // Need to get the space object from theme
  // so we can divde the spacing by 2 to get the correct spacing bw items
  let { space } = useContext(ThemeContext)

  let styles = {}
  // Spacing based on gutter
  // let isGutterValid = null //allowing specific units for gutter

  if (typeof gutter === "number") {
    styles.padding = `${space[gutter] / 2}px`
    styles.margin = `-${space[gutter] / 2}px`
  } else if (typeof gutter === "string") {
    // isGutterValid = /px/.test(gutter)
    styles.padding = `${parseInt(gutter)}px`
    styles.margin = `-${styles.padding}`
  } else if (typeof gutter === "object") {
    styles.padding = {}
    styles.margin = {}
    Object.keys(gutter).forEach((breakpoint) => {
      if (typeof gutter[breakpoint] === "number") {
        styles.padding[breakpoint] = `${space[gutter[breakpoint]] / 2}px`
        styles.margin[breakpoint] = `-${space[gutter[breakpoint]] / 2}px`
      } else if (typeof gutter[breakpoint] === "string") {
        // isGutterValid = /px/.test(gutter[breakpoint])
        styles.padding[breakpoint] = `${parseInt(gutter[breakpoint])}px`
        styles.margin[breakpoint] = `-${styles.padding[breakpoint]}`
      }
    })
  }

  let justified =
    align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center"

  // Width of child items based on columns
  if (typeof columns === "number") {
    styles.width = `${100 / columns}%`
  } else if (typeof columns === "object") {
    justified = {}
    styles.width = {}
    Object.keys(columns).forEach((breakpoint) => {
      styles.width[breakpoint] = `${100 / columns[breakpoint]}%`
      if (columns[breakpoint] === 1) {
        // if we're reducing to 1 column, we want to center horizontally
        justified[breakpoint] = "center"
      } else {
        // if more than one column, use provided justification
        justified[breakpoint] =
          align === "left"
            ? "flex-start"
            : align === "right"
            ? "flex-end"
            : "center"
      }
    })
  }

  // if children isn't a single array
  let combinedChildren = []
  children.forEach((child) => {
    if (Array.isArray(child)) {
      combinedChildren = combinedChildren.concat(child)
    } else if (child) {
      combinedChildren = combinedChildren.concat([child])
    }
  })

  // TODO: Use something other than index for the keys
  return (
    <Flex
      flexWrap="wrap"
      m={styles.margin}
      justifyContent={justified}
      {...rest}
    >
      {combinedChildren.map((child, i) => {
        let maxWidth =
          maxItemWidth === "none" || !maxItemWidth
            ? "none"
            : `${maxItemWidth}px`
        if (child) {
          return (
            <Box
              p={styles.padding}
              width={styles.width}
              height="auto"
              key={child.props.forwardKey || i}
            >
              <Box height="100%" mx="auto" maxWidth={maxWidth}>
                <Reveal height={child.props?.height || "100%"}>
                  {child.props?.height
                    ? child
                    : cloneElement(child, { height: "100%" })}
                </Reveal>
              </Box>
            </Box>
          )
        } else return null
      })}
    </Flex>
  )
}

FlexGrid.strapiProps = {
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.objectOf(PropTypes.number),
  ]),
  gutter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.objectOf(PropTypes.number),
  ]),
  align: PropTypes.oneOf(["left", "center", "right"]).isRequired,
  maxItemWidth: PropTypes.string,
}

FlexGrid.propTypes = {
  children: PropTypes.node.isRequired,
  ...FlexGrid.strapiProps,
}

export default FlexGrid

export const query = graphql`
  fragment FlexGrid on Strapi_ComponentAtomsFlexGrid {
    columns
    gutter
    align
    maxItemWidth
  }
`
