import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import ThemeSwitcher from "../utils/ThemeSwitcher"
import Link from "../utils/Link"

import Footer from "../atoms/Footer"
import Flex from "../atoms/Flex"
import { Paragraph } from "../atoms/Typography"
import StandardPadding from "../atoms/StandardPadding"
import NavLink from "../atoms/NavLink"
import MorphicBadge from "../atoms/MorphicBadge"

const FooterMinimal = ({
  theme,
  minimalTheme,
  bg,
  text,
  links,
  lastSectionStyling,
  mt,
  ...rest
}) => {
  const marginTop =
    lastSectionStyling?.sectionBreak === "slope" ? "-160px" : null
  const pt = lastSectionStyling?.sectionBreak === "slope" ? "157px" : null

  return (
    <ThemeSwitcher className="footer" theme={theme || minimalTheme}>
      <Footer
        bg={`background.${bg}`}
        mt={mt ? mt : marginTop}
        // pt={pt}
      >
        <StandardPadding py={null} pt={pt ? pt : 6} pb={6} {...rest}>
          <Flex
            flexDirection="column"
            alignItems="center"
            maxWidth={40}
            mx="auto"
          >
            {links?.length > 0 && (
              <Flex flexWrap="wrap" justifyContent="center" mb={4}>
                {links.map((link, i) => (
                  <NavLink mx={4} mb={4} {...link} key={i} display="inline" />
                ))}
              </Flex>
            )}
            {text && (
              <Paragraph as="div" textAlign="center">
                {text}
              </Paragraph>
            )}
            <MorphicBadge mt={6} />
          </Flex>
        </StandardPadding>
      </Footer>
    </ThemeSwitcher>
  )
}

export default FooterMinimal

FooterMinimal.propTypes = {
  theme: PropTypes.oneOf(["default", "light", "dark", "highlight"]),
  minimalTheme: PropTypes.oneOf(["default", "light", "dark", "highlight"]),
  bg: PropTypes.number.isRequired,
  text: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape(Link.strapiProps)),
}

export const query = graphql`
  fragment FooterMinimal on Strapi_ComponentFootersMinimal {
    minimalTheme: theme
    bg
    text
    links {
      ...Link
    }
  }
`
