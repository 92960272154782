import React from "react"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import { Link as GatsbyLink, graphql, useStaticQuery, notGatsby } from "gatsby"
import styled from "styled-components"
import { space, layout, compose } from "styled-system"
import { shouldForwardProp } from "../../lib/styled-system/helpers.js"

// Apply margin and padding to links, active
var StyledGatsbyLink = null
if (GatsbyLink) {
  StyledGatsbyLink = styled(GatsbyLink).withConfig({ shouldForwardProp })`
    ${compose(space, layout)}
  `
}

const StyledAnchor = styled("a").withConfig({ shouldForwardProp })`
  ${compose(space, layout)}
`

/**
 * Switches between a Gatsby link and a regular anchor tag based on props
 * @param {string | number} to - an internal pageId
 * @param {sectionId | string} sectionId - id of section to link to
 * @param {string} href - an external link
 * @param {string} linkText - Text for the link. Usually sent as "children".
 * @param {boolean} optional - don't include <a> tag if no link provided
 * @param {boolean} partiallyActive - Gatsby partiallyActive
 */
const Link = ({
  to,
  sectionId,
  href,
  linkText,
  optional = false,
  partiallyActive = false,
  children,
  ...rest
}) => {
  const data = useStaticQuery(graphql`
    query {
      allSitePage {
        nodes {
          context {
            pageId
          }
          path
        }
      }
    }
  `)

  // Fallback
  if (notGatsby) {
    if (!to && !href && optional) {
      return <>{children}</>
    }
    return <StyledAnchor {...rest}>{children}</StyledAnchor>
  }

  // Normal Gatsby component
  if (to) {
    let pagePath
    if (typeof to === "number") {
      // get path of page from the page ID
      const pagesDict = {}
      data.allSitePage.nodes.forEach((page) => {
        const pageId = page.context?.pageId
        if (pageId) pagesDict[pageId] = page.path
      })
      pagePath = pagesDict[to]
    } else if (typeof to === "string") {
      pagePath = to
    }

    if (partiallyActive && pagePath === "/") {
      partiallyActive = false
    }

    if (sectionId) {
      pagePath = pagePath + "#" + sectionId
    }

    if (pagePath) {
      return (
        <StyledGatsbyLink
          to={pagePath}
          activeClassName={partiallyActive ? "active" : ""}
          {...rest}
        >
          {children}
        </StyledGatsbyLink>
      )
    }
  }

  if (href) {
    // Always open external links in a new tab
    return (
      <StyledAnchor href={href} target="_blank" rel="noopener" {...rest}>
        {children}
      </StyledAnchor>
    )
  }

  if (optional) {
    return <>{children}</>
  }

  return <StyledAnchor {...rest}>{children}</StyledAnchor>
}

export default Link

Link.strapiProps = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Number corresponding to the page id or exact string path
  sectionId: PropTypes.string,
  href: PropTypes.string, // Url of external site
  linkText: PropTypes.string,
}

Link.propTypes = {
  ...Link.strapiProps,
  optional: PropTypes.bool,
  partiallyActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  ...propTypes.space,
}

export var query = graphql`
  fragment Link on Strapi_ComponentUtilsLink {
    to
    sectionId
    href
    linkText
  }
`
