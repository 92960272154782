import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Provider as AlertProvider } from "react-alert"
import { ThemeProvider } from "styled-components"
import { defaultTheme } from "../../lib/styled-system/constants"
import { mergeThemes } from "../../lib/styled-system/helpers"

import GlobalStyle from "../utils/GlobalStyle"
import Headers from "../page/Headers"
import Footers from "../page/Footers"
import Alert from "../atoms/Alert"

// can't import in RichText.js since it's a Node module
// https://github.com/vercel/next.js/issues/19936
// this import supports nested lists in rich text
import "draft-js/dist/Draft.css"

const Layout = ({ site, lastSectionStyling, footerStyle, children }) => {
  const theme = site.theme
  const completeTheme = mergeThemes(defaultTheme, theme)

  return (
    <>
      <Helmet>
        <noscript>
          {/* If there's no Javascript, show all react-reveal elements */}
          {`<style>.react-reveal {visibility: visible !important;}</style>`}
        </noscript>
      </Helmet>
      <ThemeProvider theme={completeTheme}>
        <AlertProvider
          template={Alert}
          timeout={4000}
          transition="fade"
          containerStyle={{ marginTop: "32px", zIndex: 200 }}
        >
          <GlobalStyle />
          <Headers site={site} />
          <main>{children}</main>
          <Footers
            lastSectionStyling={lastSectionStyling}
            site={site}
            {...footerStyle}
          />
        </AlertProvider>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  site: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout

export const query = graphql`
  fragment Site on Strapi_Site {
    title
    socialShareImage {
      url
    }
    favicon {
      url
    }
    siteDescription
    theme
    header {
      ...Headers
    }
    footer {
      ...Footers
    }
  }
`
