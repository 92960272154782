import React, { useState, useContext } from "react"
import { ThemeContext } from "styled-components"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import ThemeSwitcher from "../utils/ThemeSwitcher"
import Link from "../utils/Link"

import Hamburger from "../icons/Hamburger"
import Close from "../icons/Close"

import Footer from "../atoms/Footer"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import Image from "../utils/Image"
import { Paragraph } from "../atoms/Typography"
import StandardPadding from "../atoms/StandardPadding"
import MorphicBadge from "../atoms/MorphicBadge"

const BREAKPOINT_COL = "xs"

const SlideOut = styled(Box)`
  transition: transform 0.6s cubic-bezier(0.16, 0.69, 0.17, 0.99);
  will-change: transform;
  transform: ${(props) => (props.open ? "translateY(0)" : "translateY(100%)")};
`

const FooterMinimalSlide = ({
  addressTitle,
  address,
  copyrightText,
  logoDarkTheme,
  logoLightTheme,
  logoHighlightTheme,
  lastSectionStyling,
}) => {
  let currentTheme = useContext(ThemeContext)
  const [isOpen, setIsOpen] = useState(false)

  let theme = "light"
  let bg = 0
  if (lastSectionStyling) {
    theme = lastSectionStyling.theme
    bg = lastSectionStyling.bg
    if (theme === "default") theme = "light"
  }

  // get footer background color
  let footerBg = 0
  let themeBgColors = currentTheme.themes[theme].colors.background
  for (let i = 0; i < themeBgColors.length; i++) {
    if (themeBgColors[i] !== themeBgColors[bg]) {
      footerBg = i
      break
    }
  }

  let logo
  switch (theme) {
    case "dark":
      logo = logoDarkTheme
      break
    case "highlight":
      logo = logoHighlightTheme
      break
    case "light":
      logo = logoLightTheme
      break
    default:
      logo = logoLightTheme
  }
  return (
    <ThemeSwitcher theme={theme}>
      <Footer overflow="hidden" bg={`background.${bg}`}>
        <Box pt={8}>
          <SlideOut position="relative" open={isOpen}>
            <Flex
              width="100%"
              justifyContent="center"
              position="absolute"
              top={-50}
            >
              <button onClick={() => setIsOpen(!isOpen)}>
                {isOpen && <Close color="text" />}
                {!isOpen && <Hamburger color="text" />}
              </button>
            </Flex>
            <StandardPadding py={0}>
              <Flex
                px={{ _: 4, md: 8, lg: 10 }}
                py={{ _: 6, md: 8 }}
                width="100%"
                bg={`background.${footerBg}`}
                flexDirection={{ _: "column", [BREAKPOINT_COL]: "row" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex
                  width={{ _: "100%", sm: "auto" }}
                  flexDirection={{ _: "column", sm: "row" }}
                >
                  <Box
                    mr={{ _: 0, sm: 5, lg: 10 }}
                    mb={{ _: 4, sm: 0 }}
                    width={{ _: 9, md: 11 }}
                    height={3}
                  >
                    <Link to="/">
                      <Image
                        loading="eager"
                        {...logo}
                        style={{ height: "100%" }}
                        imgStyle={{
                          objectFit: "contain",
                          objectPosition: "left center",
                        }}
                      />
                    </Link>
                  </Box>
                  <Box>
                    <Paragraph lineHeight={2} fontSize={0} fontWeight="bold">
                      {addressTitle}
                    </Paragraph>
                    <Paragraph lineHeight={2} fontSize={0}>
                      {address}
                    </Paragraph>
                  </Box>
                </Flex>
                <Flex
                  width={{ _: "100%", [BREAKPOINT_COL]: "auto" }}
                  flexDirection="column"
                  mt={{ _: 6, [BREAKPOINT_COL]: 0 }}
                  alignItems={{ _: "flex-start", [BREAKPOINT_COL]: "flex-end" }}
                >
                  <MorphicBadge />
                  <Paragraph
                    textAlign={{ _: "left", [BREAKPOINT_COL]: "right" }}
                    mt={4}
                    fontSize={0}
                  >
                    {copyrightText}
                  </Paragraph>
                </Flex>
              </Flex>
            </StandardPadding>
          </SlideOut>
        </Box>
      </Footer>
    </ThemeSwitcher>
  )
}

export default FooterMinimalSlide

FooterMinimalSlide.propTypes = {
  addressTitle: PropTypes.string,
}

export const query = graphql`
  fragment FooterMinimalSlide on Strapi_ComponentFootersMinimalSlide {
    addressTitle
    address
    copyrightText
    logoDarkTheme {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 176
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
    }
    logoLightTheme {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 176
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
    }
    logoHighlightTheme {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 176
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
    }
  }
`
